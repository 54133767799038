@import "assets/scss/themeProvider.module";

.confirmationBox {
  display: flex;
  border: 2px solid $primaryMain;
  border-top-width: 2rem;
  border-radius: 5px;
  width: 220px;
  height: 120px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
}

.confirmationBoxHeadingText {
	margin-top: -2.5rem;
	margin-bottom: 2rem;
}