// paper & background
$paper: #ffffff;

// primary
$primaryLight: #dd56ad;
$primaryMain: #953372;
$primaryDark: #a4377d;
$primary200: #ffffff;
$primary800: #6d2453;

// secondary
$secondaryLight: #f9be38;
$secondaryMain: #eba607;
$secondaryDark: #c68b05;
$secondary200: #ffffff;
$secondary800: #8e6404;

// Annual Review
$annualReviewLight: #c2d8e9;
$annualReviewMain: #83b2d2;
$annualReviewDark: #3186af;
$annualReview200: #ffffff;
$annualReview800: #2a6f8a;

// Interim Review
$interimReviewLight: #c2d8e9;
$interimReviewMain: #83b2d2;
$interimReviewDark: #3186af;
$interimReview200: #ffffff;
$interimReview800: #2a6f8a;


// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

//Civida Rain
$rainLight: #c2d8e9;
$rainMain: #83b2d2;
$rainDark: #5a8fb9;

//Civida Butter
$butterLight: #f9e8b2;
$butterMain: #f2c763;
$butterDark: #e5b03b;

//Civida Sky
$skyLight: #d2f3ec;
$skyMain: #3186af;
$skyDark: #1f5c7a;

$headerBackgroundImage:  url("../../assets/images/landing/faqBands.jpg");
$rightSideCard: $primaryMain;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #d2f3ec;
$darkPrimaryMain: #59c8b2;
$darkPrimaryDark: #02c7a0;
$darkPrimary200: #b0bec5;
$darkPrimary800: #455a64;

// secondary dark
$darkSecondaryLight: #d2f3ec;
$darkSecondaryMain: #59c8b2;
$darkSecondaryDark: #02c7a0;
$darkSecondary200: #b0bec5;
$darkSecondary800: #455a64;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// user states
$existingUser: #3186AF;
$newUser: #953372;
$existingUserLoggedIn: #7D9917;
// ==============================|| JAVASCRIPT ||============================== //

:export {

	//global variables that have theme-specific values
	--headerBackgroundImage: $headerBackgroundImage;
	--rightSideCard: $rightSideCard;

	// paper & background
	paper: $paper;

	// primary
	primaryLight: $primaryLight;
	primary200: $primary200;
	primaryMain: $primaryMain;
	primaryDark: $primaryDark;
	primary800: $primary800;

	// secondary
	secondaryLight: $secondaryLight;
	secondary200: $secondary200;
	secondaryMain: $secondaryMain;
	secondaryDark: $secondaryDark;
	secondary800: $secondary800;

	// success
	successLight: $successLight;
	success200: $success200;
	successMain: $successMain;
	successDark: $successDark;

	// error
	errorLight: $errorLight;
	errorMain: $errorMain;
	errorDark: $errorDark;

	// orange
	orangeLight: $orangeLight;
	orangeMain: $orangeMain;
	orangeDark: $orangeDark;

	// warning
	warningLight: $warningLight;
	warningMain: $warningMain;
	warningDark: $warningDark;

	// grey
	grey50: $grey50;
	grey100: $grey100;
	grey200: $grey200;
	grey300: $grey300;
	grey500: $grey500;
	grey600: $grey600;
	grey700: $grey700;
	grey900: $grey900;

	//Civida Rain
	rainLight: $rainLight;
	rainMain: $rainMain;
	rainDark: $rainDark;

	//Civida Butter
	butterLight: $butterLight;
	butterMain: $butterMain;
	butterDark: $butterDark;

	//Civida Sky
	skyLight: $skyLight;
	skyMain: $skyMain;
	skyDark: $skyDark;

	existingUser: $existingUser;
	newUser: $newUser;
	existingUserLoggedIn: $existingUserLoggedIn;

	// ==============================|| DARK THEME VARIANTS ||============================== //

	// paper & background
	darkPaper: $darkPaper;
	darkBackground: $darkBackground;

	// dark 800 & 900
	darkLevel1: $darkLevel1;
	darkLevel2: $darkLevel2;

	// text variants
	darkTextTitle: $darkTextTitle;
	darkTextPrimary: $darkTextPrimary;
	darkTextSecondary: $darkTextSecondary;

	// primary dark
	darkPrimaryLight: $darkPrimaryLight;
	darkPrimaryMain: $darkPrimaryMain;
	darkPrimaryDark: $darkPrimaryDark;
	darkPrimary200: $darkPrimary200;
	darkPrimary800: $darkPrimary800;

	// secondary dark
	darkSecondaryLight: $darkSecondaryLight;
	darkSecondaryMain: $darkSecondaryMain;
	darkSecondaryDark: $darkSecondaryDark;
	darkSecondary200: $darkSecondary200;
	darkSecondary800: $darkSecondary800;	
}
